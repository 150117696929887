import React from "react";
import { Phone, Mail } from "tabler-icons-react";

const Contact = () => {
  return (
    <section id="contact" className="py-36 bg-white font-general-sans-regular">
    <div className="container mx-auto flex flex-col gap-8 text-center">
      <h2 className="text-3xl text-uhubblue font-neue-bold">Contact Us</h2>
      <p className="text-lg text-gray-700">For any inquiries or issues,<br className="md:hidden"/> please contact us at:</p>
      <div className="flex flex-col md:flex-row gap-4 md:gap-8 justify-center items-center">
      <button className="bg-[#e5444c] rounded-full flex flex-row items-center gap-2 px-8 py-4">
      <Mail className="w-6 h-6 text-white text-xl" />
            <a href="mailto:info@theuhub.in" className="text-white font-general-sans-medium text-xl">
              info@theuhub.in
            </a>
      </button>
      <button className="bg-[#ECBF02] rounded-full flex flex-row items-center gap-2 px-8 py-4">
      <Phone className="w-6 h-6 text-white text-xl" />
      <a href="tel:+918925909076" className="text-white font-general-sans-medium text-xl">
  +91 89259 09076
</a>
      </button>
      </div>
    </div>
  </section>
  );
};

export default Contact;
