import React from 'react';
import chennaiIcon from "../Assets/SVG/Chennai.svg";
import coimbatoreIcon from "../Assets/SVG/Coimbatore.svg";
import trichyIcon from "../Assets/SVG/Trichy.svg";
import salemIcon from "../Assets/SVG/Salem.svg";
import maduraiIcon from "../Assets/SVG/Madurai.svg";

import { Phone, Mail, Location } from "tabler-icons-react";

const BranchesPage = () => {
  return (
    <div>
      <section className="my-16 px-4 md:px-8 lg:px-16 gap-16 md:gap-32 text-uhubblue">
        <div className="flex flex-col items-center gap-16">
          <div className="text-center mb-16">
            <div className="mb-20" /> {/* Line break / spacing */}
            <div className="font-general-sans-semibold text-base uppercase">
              Our Branches
            </div>
            <div className="font-neue-bold text-2xl md:text-5xl md:leading-[56px]">
              Strategic Locations That Drive Excellence
            </div>
          </div>
        </div>
        
        {/* Chennai Section */}
        <div className="flex justify-center">
          <div className="font-general-sans-semibold text-base uppercase text-center">
            CHENNAI
          </div>
        </div>
        <div className="mb-10" />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Chennai Branches */}
          <div className="bg-[#FDF7EF] border border-gray-300 flex flex-col gap-2 p-6 rounded-lg">
            <div className="flex items-center mb-2">
              <img src={chennaiIcon} alt="Chennai" className="w-8 h-8 mr-2" />
              <h3 className="text-base md:text-2xl font-bold">
                Chennai <span className="text-sm font-general-sans-regular">(Head Office)</span>
              </h3>
            </div>
            <div className="mb-2">
              <p className="text-sm text-gray-700">
                <span className="block mb-2">
                  D.No: 35/51, Naval Hospital Road, Periyamedu, Chennai - 600003
                </span>
                <a href="https://maps.app.goo.gl/zp1JkRSA3kD6iNUo8" className="text-white bg-blue-500 rounded-full px-3 py-1 mb-2">
                  View in maps
                </a>
              </p>
            </div>
            <div>
              
              <p className="flex items-center text-sm text-gray-700">
                <Mail className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="mailto:info@theuhub.in" className="text-gray-700">
                  info@theuhub.in
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Phone className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="tel:+918925837393" className="text-blue-500">
                  +91 89258 37393
                </a>
              </p>
            </div>
          </div>
          <div className="bg-[#FDF7EF] border border-gray-300 flex flex-col gap-2 p-6 rounded-lg">
            <div className="flex items-center mb-2">
              <img src={chennaiIcon} alt="Chennai" className="w-8 h-8 mr-2" />
              <h3 className="text-base md:text-2xl font-bold">
                Irungattukottai <span className="text-sm font-general-sans-regular"></span>
              </h3>
            </div>
            <div className="mb-2">
              <p className="text-sm text-gray-700">
                <span className="block mb-2">
                No: 424, Irungattukottai,Main Road,Kanchipuram - 602117
                </span>
                <a href="" className="text-white bg-blue-500 rounded-full px-3 py-1 mb-2">
                  View in maps
                </a>
              </p>
            </div>
            <div>
              
              <p className="flex items-center text-sm text-gray-700">
                <Mail className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="mailto:info@theuhub.in" className="text-gray-700">
                  info@theuhub.in
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Phone className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="tel:+918925837396" className="text-blue-500">
                  +91 89258 37396
                </a>
              </p>
            </div>
          </div>
          <div className="bg-[#FDF7EF] border border-gray-300 flex flex-col gap-2 p-6 rounded-lg">
            <div className="flex items-center mb-2">
              <img src={chennaiIcon} alt="Chennai" className="w-8 h-8 mr-2" />
              <h3 className="text-base md:text-2xl font-bold">
              Mannivakkam<span className="text-sm font-general-sans-regular"></span>
              </h3>
            </div>
            <div className="mb-2">
              <p className="text-sm text-gray-700">
                <span className="block mb-2">
                No: 3, Sri Complex,Walajabad Road, Thangavel Nagar,Mannivakkam, Chennai-600048
                </span>
                <a href="" className="text-white bg-blue-500 rounded-full px-3 py-1 mb-2">
                  View in maps
                </a>
              </p>
            </div>
            <div>
              
              <p className="flex items-center text-sm text-gray-700">
                <Mail className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="mailto:info@theuhub.in" className="text-gray-700">
                  info@theuhub.in
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Phone className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="tel:+91 8925837396" className="text-blue-500">
                +91 89258 37393
                </a>
              </p>
            </div>
          </div>
          <div className="bg-[#FDF7EF] border border-gray-300 flex flex-col gap-2 p-6 rounded-lg">
            <div className="flex items-center mb-2">
              <img src={chennaiIcon} alt="Chennai" className="w-8 h-8 mr-2" />
              <h3 className="text-base md:text-2xl font-bold">
              Madhuravayal<span className="text-sm font-general-sans-regular"></span>
              </h3>
            </div>
            <div className="mb-2">
              <p className="text-sm text-gray-700">
                <span className="block mb-2">
                No: 1/73B, Poonamallee Main Road,Vanagaram, Madhuravayal,Chennai-600095
                </span>
                <a href="" className="text-white bg-blue-500 rounded-full px-3 py-1 mb-2">
                  View in maps
                </a>
              </p>
            </div>
            <div>
              
              <p className="flex items-center text-sm text-gray-700">
                <Mail className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="mailto:info@theuhub.in" className="text-gray-700">
                  info@theuhub.in
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Phone className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="tel:+9189258 37393" className="text-blue-500">
                  +91 89258 37393
                </a>
              </p>
            </div>
          </div>
          
          <div className="bg-[#FDF7EF] border border-gray-300 flex flex-col gap-2 p-6 rounded-lg">
            <div className="flex items-center mb-2">
              <img src={chennaiIcon} alt="Chennai" className="w-8 h-8 mr-2" />
              <h3 className="text-base md:text-2xl font-bold">Madhavaram</h3>
            </div>
            <div className="mb-2">
              <p className="text-sm text-gray-700">
                <span className="block mb-2">
                  No: 504/4, Srijith Complex, Jawaharlal Nehru Road, 200 ft. Inner ring road, Madhavaram, Chennai-600060
                </span>
                <a href="" className="text-white bg-blue-500 rounded-full px-3 py-1">
                  View in maps
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Mail className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="mailto:info@theuhub.in" className="text-gray-700">
                  info@theuhub.in
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Phone className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="tel:+91 89258 37393" className="text-blue-500">
                +91 89258 37393
                </a>
              </p>
            </div>
          </div>
            
          <div className="bg-[#FDF7EF] border border-gray-300 flex flex-col gap-2 p-6 rounded-lg">
            <div className="flex items-center mb-2">
              <img src={chennaiIcon} alt="Chennai" className="w-8 h-8 mr-2" />
              <h3 className="text-base md:text-2xl font-bold">Parrys</h3>
            </div>
            <div className="mb-2">
              <p className="text-sm text-gray-700">
                <span className="block mb-2">
                  New no: 47/ Old No: 24, Thambu Chetty street, Chennai-600001
                </span>
                <a href="" className="text-white bg-blue-500 rounded-full px-3 py-1">
                  View in maps
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Mail className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="mailto:info@theuhub.in" className="text-gray-700">
                  info@theuhub.in
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Phone className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="tel:+91 89258 37396" className="text-blue-500">
                +91 89258 37396
                </a>
              </p>
            </div>
          </div>
          
          <div className="bg-[#FDF7EF] border border-gray-300 flex flex-col gap-2 p-6 rounded-lg">
            <div className="flex items-center mb-2">
              <img src={chennaiIcon} alt="Chennai" className="w-8 h-8 mr-2" />
              <h3 className="text-base md:text-2xl font-bold">Waltax Road</h3>
            </div>
            <div className="mb-2">
              <p className="text-sm text-gray-700">
                <span className="block mb-2">
                  No: 1/10, Venkatrayan Street, Park Town, Chennai-600003
                </span>
                <a href="" className="text-white bg-blue-500 rounded-full px-3 py-1">
                  View in maps
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Mail className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="mailto:info@theuhub.in" className="text-gray-700">
                  info@theuhub.in
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Phone className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="tel:+91 89258 37393" className="text-blue-500">
                  +91 89258 37393
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* Coimbatore Section */}
        <div className="flex flex-col items-center my-16">
          <div className="font-general-sans-semibold text-base uppercase text-center mb-8">
            COIMBATORE
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
             {/* Coimbatore Branch 1 */}
            <div className="bg-[#FDF7EF] border border-gray-300 flex flex-col gap-2 p-6 rounded-lg">
              <div className="flex items-center mb-2">
                <img src={coimbatoreIcon} alt="Coimbatore" className="w-8 h-8 mr-2"/>
                <h3 className="text-base md:text-2xl font-bold">Sidhapudhur</h3>
              </div>
              <div className="mb-2">
                <p className="text-sm text-gray-700">
                  <span className="block mb-2">
                   D.No : 472-474 Bharathiyar Road, Pappanaicken Palayam, Coimbatore - 641037
                  </span>
                  <a href="" className="text-white bg-blue-500 rounded-full px-3 py-1">
                    View in maps
                  </a>
                </p>
              </div>
              <div>
                <p className="flex items-center text-sm text-gray-700">
                  <Mail className="w-4 h-4 mr-2 text-uhubblue" />
                  <a href="mailto:info@theuhub.in" className="text-gray-700">
                    info@theuhub.in
                  </a>
                </p>
              </div>
              <div>
                <p className="flex items-center text-sm text-gray-700">
                  <Phone className="w-4 h-4 mr-2 text-uhubblue" />
                  <a href="tel:+918925909078" className="text-blue-500">
                    +91 89259 09078
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
         {/* Erode Section */}
         <div className="flex justify-center mt-12">
          <div className="font-general-sans-semibold text-base uppercase text-center">
            Erode
          </div>
        </div>
        <div className="mb-10" />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Erode Branches */}
          <div className="bg-[#FDF7EF] border border-gray-300 flex flex-col gap-2 p-6 rounded-lg">
            <div className="flex items-center mb-2">
              <img src={maduraiIcon} alt="Erode" className="w-8 h-8 mr-2" />
              <h3 className="text-base md:text-2xl font-bold">Erode</h3>
            </div>
            <div className="mb-2">
              <p className="text-sm text-gray-700">
                <span className="block mb-2">
                 #91, Old Rajaram Theatre Opposite, Park Road, Erode-638003.
                </span>
                <a href="" className="text-white bg-blue-500 rounded-full px-3 py-1">
                  View in maps
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Mail className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="mailto:info@theuhub.in" className="text-gray-700">
                info@theuhub.in
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Phone className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="tel:+919003073132" className="text-blue-500">
                +91 90030 73132
                </a>
              </p>
            </div>
          </div>
        </div>

        {/* Madurai Section */}
        <div className="flex justify-center mt-12">
          <div className="font-general-sans-semibold text-base uppercase text-center">
            MADURAI
          </div>
        </div>
        <div className="mb-10" />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Madurai Branches */}
          <div className="bg-[#FDF7EF] border border-gray-300 flex flex-col gap-2 p-6 rounded-lg">
            <div className="flex items-center mb-2">
              <img src={maduraiIcon} alt="Madurai" className="w-8 h-8 mr-2" />
              <h3 className="text-base md:text-2xl font-bold">Madurai</h3>
            </div>
            <div className="mb-2">
              <p className="text-sm text-gray-700">
                <span className="block mb-2">
                  D.No: 951 Plotno :30a,80 Feet Road, Anna Nagar, Alavandan,Madurai-625020
                </span>
                <a href="" className="text-white bg-blue-500 rounded-full px-3 py-1">
                  View in maps
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Mail className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="mailto:info@theuhub.in" className="text-gray-700">
                info@theuhub.in
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Phone className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="tel:+91 8925909075" className="text-blue-500">
                +91 89259 09075
                </a>
              </p>
            </div>
          </div>
        </div>
        {/* SALEM Section */}
        <div className="flex justify-center mt-12">
          <div className="font-general-sans-semibold text-base uppercase text-center">
            SALEM
          </div>
        </div>
        <div className="mb-10" />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Salem Branches */}
          <div className="bg-[#FDF7EF] border border-gray-300 flex flex-col gap-2 p-6 rounded-lg">
            <div className="flex items-center mb-2">
              <img src={salemIcon} alt="salem" className="w-8 h-8 mr-2" />
              <h3 className="text-base md:text-2xl font-bold">Salem</h3>
            </div>
            <div className="mb-2">
              <p className="text-sm text-gray-700">
                <span className="block mb-2">
                D.No: 1/251, Bangalore By-Pass Road, Periya Kondalampatty (PO), Kondalampatty, Salem - 636010
                </span>
                <a href="https://maps.app.goo.gl/XYZ" className="text-white bg-blue-500 rounded-full px-3 py-1">
                  View in maps
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Mail className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="mailto:info@theuhub.in" className="text-gray-700">
                info@theuhub.in
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Phone className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="tel:++91 8925909077" className="text-blue-500">
                +91 89259 09077
                </a>
              </p>
            </div>
          </div>
          </div>
        {/* Trichy Section */}
        <div className="flex justify-center mt-12">
          <div className="font-general-sans-semibold text-base uppercase text-center">
            TRICHY
          </div>
        </div>
        <div className="mb-10" />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Trichy Branches */}
          <div className="bg-[#FDF7EF] border border-gray-300 flex flex-col gap-2 p-6 rounded-lg">
            <div className="flex items-center mb-2">
              <img src={trichyIcon} alt="Trichy" className="w-8 h-8 mr-2" />
              <h3 className="text-base md:text-2xl font-bold">Ariyamangalam</h3>
            </div>
            <div className="mb-2">
              <p className="text-sm text-gray-700">
                <span className="block mb-2">
                D.No: 53 E-1, SIDCO Industrial Estate Ariyamangalam Area, Tiruchirappalli-620010
                </span>
                <a href="" className="text-white bg-blue-500 rounded-full px-3 py-1">
                  View in maps
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Mail className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="mailto:trichy@theuhub.in" className="text-gray-700">
                  trichy@theuhub.in
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Phone className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="tel:+91 89258 37397" className="text-blue-500">
                +91 89258 37397
                </a>
              </p>
            </div>
          </div>
          <div className="bg-[#FDF7EF] border border-gray-300 flex flex-col gap-2 p-6 rounded-lg">
            <div className="flex items-center mb-2">
              <img src={trichyIcon} alt="Trichy" className="w-8 h-8 mr-2" />
              <h3 className="text-base md:text-2xl font-bold">
                Thennur
                <span className="text-sm font-general-sans-regular"></span>
              </h3>
            </div>
            <div className="mb-2">
              <p className="text-sm text-gray-700">
                <span className="block mb-2">
                D.No: 132, General Bazaar Street, Thennur, Trichy - 620017
                </span>
                <a href="https://maps.app.goo.gl/zp1JkRSA3kD6iNUo8" className="text-white bg-blue-500 rounded-full px-3 py-1 mb-2">
                  View in maps
                </a>
              </p>
            </div>
            <div>
              
              <p className="flex items-center text-sm text-gray-700">
                <Mail className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="mailto:info@theuhub.in" className="text-gray-700">
                info@theuhub.in
                </a>
              </p>
            </div>
            <div>
              <p className="flex items-center text-sm text-gray-700">
                <Phone className="w-4 h-4 mr-2 text-uhubblue" />
                <a href="tel:+918925909071" className="text-blue-500">
                  +91 89259 09071
                </a>
              </p>
            </div>
          </div>
          </div>
      </section>
    </div>
  );
};

export default BranchesPage;
