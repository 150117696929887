import React from "react";
import Ecom from "../Assets/Images/E-commerce.jpg";

const Services = () => {
  return (
    <div id="services" className="pt-8 md:pt-16">
    <div className="bg-lightorange py-8 md:py-16">
    <section  className="mx-5  gap-8 md:gap-16 md:mx-16 md:flex md:flex-col text-uhubblue">
      <div className="flex flex-col items-center justify-center gap-4 md:gap-8 md:w-2/3 mx-auto">
       <div className="flex flex-col items-center justify-center gap-4 md:gap-8 mx-auto">
        
        <div className=" font-general-sans-semibold text-base uppercase">
          OUR SERVICES
        </div>

        <div className="text-center font-neue-bold text-2xl md:space-y-2 md:text-5xl">
        Tailored Logistics Services for You
        </div>
        </div>

        <div className="font-general-sans-regular leading-normal text-center text-sm">
        Discover the essence of U Hub through our comprehensive services. From reliable Courier and Cargo solutions to efficient Parcel delivery, our state-of-the-art technology with professionally streamlined operations our  services ensure that your logistics needs are met with excellence, satisfaction and precision. Join us and experience the seamless efficiency and dedicated expertise that define U Hub.
        </div>
      </div>

      
    <div className="container mx-auto mt-8 md:mt-0 text-center">
        <div className="md:mx-40 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            <div className="bg-white rounded-lg shadow-md">
                <div>
                    <img src="https://jmor.com/uploads/Man%20in%20Warehouse%20with%20Mask.jpg" alt="Courier Service" className="w-full h-40 object-cover rounded-t-lg" />
                </div>
                <div className="p-6">
                    <h3 className="text-2xl font-bold mb-2">Courier Service</h3>
                    <p className="text-gray-700">Efficient and reliable courier services to ensure timely delivery.</p>
                </div>
            </div>
            
            <div className="bg-white rounded-lg shadow-md">
                <div>
                    <img src="https://pageloot.com/wp-content/uploads/2021/10/benefits-of-using-a-barcode-reader-1024x560.jpg" alt="Parcel Service" className="w-full h-40 object-cover rounded-t-lg" />
                </div>
                <div className="p-6">
                    <h3 className="text-2xl font-bold mb-2">Parcel Service</h3>
                    <p className="text-gray-700">Affordable parcel services for small and medium-sized packages.</p>
                </div>
            </div>

            <div className="bg-white rounded-lg shadow-md">
                <div>
                    <img src={Ecom} alt="Courier Service" className="w-full h-40 object-cover rounded-t-lg" />
                </div>
                <div className="p-6">
                    <h3 className="text-2xl font-bold mb-2">E-Commerce Delivery</h3>
                    <p className="text-gray-700">Streamlined E-Commerce delivery services for guaranteed on-time deliveries.</p>
                </div>
            </div>

            <div className="bg-white rounded-lg shadow-md">
                <div>
                    <img src="https://www.adsodxb.com/adso-uploads/2021/10/Logistics-Company-scaled.jpg" alt="Cargo Service" className="w-full h-40 object-cover rounded-t-lg" />
                </div>
                <div className="p-6">
                    <h3 className="text-2xl font-bold mb-2">Cargo Service</h3>
                    <p className="text-gray-700">Safe and secure cargo services for your bulk shipments.</p>
                </div>
            </div>
        </div>
    </div>

    </section>
    </div>
    </div>
  );
};

export default Services;
