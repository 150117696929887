import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header';
import Slider from './Components/Slider';
import AboutUs from './Components/AboutUs';
import Services from './Components/Services';
import Footer from './Components/Footer';
import BranchesPage from './Components/BranchesPage';
import Franchise from './Components/Franchise';
import Careers from './Components/Careers';
import FAQs from './Components/FAQs';
import Contact from './Components/Contact';
import MobileNavbar from './Components/MobileNavbar';
import WhatsappButton from './Components/WhatsappButton';


function Homepage() {
  return (
    <>
      <Slider />
      <section id="tracking" className='flex flex-col justify-center items-center'>
        
        <AboutUs/>
        <Services/>
      </section>
      <section id="franchise" className='mx-5 md:gap-16 md:mx-16 flex flex-col justify-center items-center'>
        <Franchise/>
        <FAQs/>
      </section>
      <Careers id="careers"/>
      <Contact id="contact"/>
    </>
  );
}

function App() {
  return (
    <Router>
      <MobileNavbar/>
      <Header/>
      <Routes>
        <Route path="/branches" element={<BranchesPage />} />
        <Route path="/" element={<Homepage />} />
      </Routes>
      <WhatsappButton />
      <Footer/>
    </Router>
  );
}

export default App;
