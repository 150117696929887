import React, { useState } from "react";

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Set the initial activeIndex to 0

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <section className="py-16 text-uhubblue">
      <div className="md:w-max-full flex flex-col md:flex-row space-y-8 md:space-y-0">
        <div className="md:w-2/5 md:pr-16 flex flex-col text-center md:text-start space-y-4 md:space-y-6 justify-start">
          <div className="font-general-sans-semibold text-base uppercase">
            Terms & Conditions
          </div>
          <div className="font-neue-bold text-2xl md:text-5xl md:leading-[56px]">
          Your Guide to Seamless Logistics Operations
          </div>
          <div className="font-general-sans-regular text-sm md:pr-12">
            Frequently asked questions ordered by popularity. Remember that if
            the visitor has not committed to the call to action, they may still
            have questions (doubts) that can be answered.
          </div>
        </div>

        <div className="md:w-3/5 w-max-full flex flex-col gap-4 md:gap-8 relative">
          {faqData.map((faq, index) => (
            <div key={index}>
              <div
                className="flex justify-between cursor-pointer"
                onClick={() => toggleAccordion(index)}
              >
                <div className="w-4/5 flex flex-col">
                  <span className="font-general-sans-semibold text-base">
                    {faq.question}
                  </span>
                  <span className={`w-full md:w-2/3 font-general-sans-regular text-base mt-2 md:my-4 ${activeIndex === index ? 'block' : 'hidden'}`}>
                    {faq.answer}
                  </span>
                </div>
                <div className="items-center">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`transform transition-transform ${
                      activeIndex === index ? 'rotate-180' : ''
                    }`}
                  >
                    <g clipPath="url(#clip0_5_83)">
                      <path
                        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                        stroke="#1D1E1C"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 14L16 19L21 14"
                        stroke="#1D1E1C"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5_83">
                        <rect width="32" height="32" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              {/* Conditionally render the divider between question and answer pairs, excluding the last pair */}
              {index !== faqData.length - 1 && <div className="relative inset-x-0 top-0 w-2/5 md:w-1/6 h-[1.5px] mt-4 bg-black"></div>}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const faqData = [
  {
    question: "Not to book consignments that are considered illegal or contraband?",
    answer:
      "Consignments that are illegal, contraband, restricted, or prohibited by any statute, act, law, rule, or regulation in India or under any other law should not be booked.",
  },
  {
    question: "What documentation is required for booking?",
    answer:
      "A clear, full, and legible delivery address with pin code/zip codes and necessary documentation like E-way Bill, E-Invoice, Bills, Delivery Notes, etc. is required while booking or on request by U HUB.",
  },
  {
    question: "Is declaring the true value of the consignment necessary?",
    answer:
      "Yes, declaring the true value of the consignment while booking is mandatory.",
  },
  {
    question: "Is safe packing of consignments required?",
    answer:
      "Safe packing of consignments is a pre-requisite unless expressly opted for. U HUB will not be responsible for any consequences arising from poor packing.",
  },
  {
    question: "What happens if the consignee fails to take delivery?",
    answer:
      "Unless expressly consented by the consignor, demurrage will be levied at the rate of 10% on the content value for each day after the expiry of 5 days of reaching the destination and payable by the consignor at the time of taking delivery either by the consignor or consignee.",
  },
  {
    question: "What happens if dues are not paid on time?",
    answer:
      "The law of bailment of goods will be applicable for failure to pay the dues in time to U HUB.",
  },
  {
    question: "Can U HUB check the contents of the consignment?",
    answer:
      "It is considered lawful by U HUB to check the contents of the consignment in case of any doubts and either proceed to arrange for dispatch and delivery or even refuse to dispatch the consignments to protect the interest of U HUB and other consignors.",
  },
  {
    question: "Does U HUB have discretion over holding and dispatching consignments?",
    answer:
      "U HUB reserves the right of discretion to hold and dispatch the consignment.",
  },
  {
    question: "Where can civil proceedings be instituted in case of disputes?",
    answer:
      "In case of any disputes, the jurisdiction to institute any civil proceedings is Chennai only.",
  },
  {
    question: "Is insurance for consignments necessary?",
    answer:
      "It is the consignor’s responsibility to avail insurance for the consignments. U HUB would render all assistance to either secure the parcel or issue a 'Missing Certificate' in the absence of the former.",
  },
];


export default FAQs;
