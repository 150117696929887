import React from "react";
import { Link } from "react-router-dom";
import logo from "../Assets/Others/Logo.svg";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bottom-0 left-0 md:w-full md:max-w-screen text-uhubblue">
      <div className="font-general-sans-light text-sm flex flex-col md:flex-row text-center md:text-start md:justify-between p-12 mx-5 md:mx-16 bg-orange md:w-auto h-auto md:h-[310px] rounded-cow md:p-12">
        {/* Black Box */}
        <div className="md:w-1/2 md:pr-32 flex flex-col gap-8 justify-center md:justify-start">
          {/* Logo with Subscribe */}
          <div className="w-56 flex justify-center md:justify-start">
            <img src={logo} alt="U hub Logo" />
          </div>

          <div className="text-base">
            Join our newsletter to stay up to date on features and releases.
          </div>
          <div className="flex flex-col gap-4 md:gap-0 md:flex-row justify-between">
            <input
              className="border-b border-uhubblue placeholder:text-base placeholder-uhubblue w-full md:p-3 bg-transparent mr-6 focus:border-none focus:ring-0 md:items-start"
              placeholder="Enter your email"
            />
            <button className="mx-auto bg-uhubblue font-general-sans-semibold text-sm text-white rounded-full px-6 py-3 md:items-end inline-block">
              Subscribe
            </button>
          </div>
        </div>

        <div className="mt-8 md:mt-6 md:w-1/2">
          {/* List */}
          <div className="flex flex-col md:flex-row justify-between gap-8 md:gap-0">
            <div className="flex flex-col gap-2 md:w-1/3 md:gap-0">
              {/* First Column */}
              <h2 className="font-general-sans-semibold uppercase text-sm">
                Resources
              </h2>
              <ul className="flex flex-col gap-2 md:py-2.5 md:gap-0">
                <li className="md:py-2.5">
                  <Link to="/" onClick={() => window.scrollTo({ top: document.querySelector("#aboutus")?.offsetTop - 64, behavior: 'smooth' })} className="text-base hover:font-general-sans-medium">About us</Link>
                </li>
                <li className="md:py-2.5">
                  <Link to="/" onClick={() => window.scrollTo({ top: document.querySelector("#services")?.offsetTop - 64, behavior: 'smooth' })} className="text-base hover:font-general-sans-medium">Services</Link>
                </li>
                <li className="md:py-2.5">
                  <Link to="/branches" className="text-base hover:font-general-sans-medium">Branches</Link>
                </li>
                
              </ul>
            </div>

            <div className="md:w-1/3 flex flex-col gap-2 md:gap-0">
              {/* Second Column */}
              <h2 className="font-general-sans-semibold uppercase text-sm">
                Stay Connected
              </h2>
              <ul className="flex flex-col gap-2 md:py-2.5 md:gap-0">
                <li className="md:py-2.5">
                  <Link to="/" onClick={() => window.scrollTo({ top: document.querySelector("#franchise")?.offsetTop - 64, behavior: 'smooth' })} className="text-base hover:font-general-sans-medium">Franchise</Link>
                </li>
                <li className="md:py-2.5">
                  <Link to="/" onClick={() => window.scrollTo({ top: document.querySelector("#careers")?.offsetTop - 64, behavior: 'smooth' })} className="text-base hover:font-general-sans-medium">Careers</Link>
                </li>
                <li className="md:py-2.5">
                  <Link to="/" onClick={() => window.scrollTo({ top: document.querySelector("#contact")?.offsetTop - 64, behavior: 'smooth' })} className="text-base hover:font-general-sans-medium">Contact us</Link>
                </li>
              </ul>
            </div>

            <div className="flex flex-col gap-2 md:w-1/3">
              {/* Third Column */}
              <h2 className="font-general-sans-semibold uppercase text-sm">
                Follow us
              </h2>
              <ul className="flex flex-col gap-2 md:py-2.5 md:gap-0">
                <li className="md:py-2.5">
                  <a className="text-base hover:font-general-sans-medium" href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
                </li>
                <li className="md:py-2.5">
                  <a className="text-base hover:font-general-sans-medium" href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
                </li>
                <li className="md:py-2.5">
                  <a className="text-base hover:font-general-sans-medium" href="https://linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden md:flex flex-col text-sm font-neue-regular text-uhubblue md:h-[25px] mx-5 md:mx-16 my-8 gap-4 md:gap-0 justify-center items-center md:flex-row md:justify-center">
        {/* Copyright-Mobile */}
        <span>
          &copy; <span>{currentYear}</span> U Hub Logistics. All rights reserved.
        </span>
      </div>

      <div className="md:hidden text-sm font-neue-regular text-uhubblue md:h-[25px] mx-5 md:mx-16 my-8 flex flex-col gap-2 md:gap-0 justify-center items-center md:flex-row md:justify-center">
        {/* Copyright */}
        <span>
          &copy; <span>{currentYear}</span> U Hub. All rights reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
