import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import Slide1 from '../Assets/Images/s1.jpg';
import Slide2 from '../Assets/Images/s2.jpg';
import Slide3 from '../Assets/Images/s3.jpg';

const Slider = () => {
  const location = useLocation();
  const [heightRatio, setHeightRatio] = useState(0.75);

  // Determine whether to show the slider
  const shouldShowSlider = location.pathname === '/' || location.pathname === '/home';

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setHeightRatio(0.35); // medium devices (md)
      } else {
        setHeightRatio(0.5); // Default ratio for other screen sizes
      }
    };

    // Initial setup
    handleResize();
    // Event listener for window resize
    window.addEventListener('resize', handleResize);
    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures useEffect runs only once on component mount

  // Use useEffect to alert the updated heightRatio
  useEffect(() => {
    console.log("Height Now : " + heightRatio);
  }, [heightRatio]);

  if (!shouldShowSlider) return null; // Conditionally render slider

  return (
    <div className="mt-16 md:mt-20">
      <Splide
        options={{
          heightRatio: heightRatio,
          type: 'loop',
          perPage: 1,
          perMove: 1,
          focus: 'center',
          autoplay: true,
          autoScroll: {
            speed: 1,
          },
          interval: 2500,
          arrows: false, // Disable arrows
        }}
      >
        <SplideSlide className="flex justify-center items-center h-full">
          <img src={Slide1} alt="Slide 1" className="w-full h-full object-cover" />
        </SplideSlide>

        <SplideSlide className="flex justify-center items-center h-full">
          <img src={Slide2} alt="Slide 2" className="w-full h-full object-cover" />
        </SplideSlide>

        <SplideSlide className="flex justify-center items-center h-full">
          <img src={Slide3} alt="Slide 3" className="w-full h-full object-cover" />
        </SplideSlide>
      </Splide>
    </div>
  );
};

export default Slider;
