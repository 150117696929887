import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../Assets/Others/Logo.svg";
import SmoothScroll from 'smooth-scroll';

function Header() {
  const [selectedLink, setSelectedLink] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    new SmoothScroll('a[href*="#"]', {
      speed: 800,
      speedAsDuration: true,
      offset: 64, // Adjust this value to match your header height
    });
  }, []);

  useEffect(() => {
    const scrollToHash = () => {
      if (location.hash) {
        const element = document.querySelector(location.hash);
        if (element) {
          window.scrollTo({
            top: element.offsetTop - 64, // Adjust based on your header height
            behavior: 'smooth'
          });
        }
      }
    };

    scrollToHash();
  }, [location]);

  const handleLinkClick = (link, path) => {
    setSelectedLink(link);
    if (path === "/branches") {
      navigate(path);
    } else {
      navigate("/", { state: { scrollTo: link } });
    }
  };

  return (
    <header className="fixed top-0 left-0 w-full text-gray-600 bg-white z-30 text-base border-b border-b-gray-100 font-general-sans-regular leading-none py-3.5 px-5 h-16 md:h-20 md:px-5 md:flex md:flex-row md:items-center md:justify-between">
      <div className="w-32 md:w-40 md:ml-12 md:py-2">
        <img src={logo} alt="Company Logo" />
      </div>

      <nav className="hidden md:flex md:flex-row md:items-center md:gap-12 md:mr-12">
        <a
          href="#aboutus"
          className={selectedLink === "aboutus" ? "font-general-sans-medium text-black" : ""}
          onClick={() => handleLinkClick("aboutus", "/")}
        >
          About us
        </a>
        
        <a
          href="#services"
          className={selectedLink === "services" ? "font-general-sans-medium text-black" : ""}
          onClick={() => handleLinkClick("services", "/")}
        >
          Services
        </a>
        <Link
          to="/branches"
          className={selectedLink === "branches" ? "font-general-sans-medium text-black" : ""}
          onClick={() => handleLinkClick("branches", "/branches")}
        >
          Branches
        </Link>
        <a
          href="#franchise"
          className={selectedLink === "franchise" ? "font-general-sans-medium text-black" : ""}
          onClick={() => handleLinkClick("franchise", "/")}
        >
          Franchise
        </a>
        <a
          href="#careers"
          className={selectedLink === "careers" ? "font-general-sans-medium text-black" : ""}
          onClick={() => handleLinkClick("careers", "/")}
        >
          Careers
        </a>
        <a
          href="#contact"
          className={selectedLink === "contact" ? "font-general-sans-medium text-black" : ""}
          onClick={() => handleLinkClick("contact", "/")}
        >
          Contact us
        </a>
      </nav>
    </header>
  );
}

export default Header;
