import React from "react";

const Franchise = () => {
  return (
    <div id="franchise" className="pt-8 md:pt-16">
    <div className="bg-lightorange rounded-full py-4 md:py-8">
    <section className="mx-5 my-8 gap-4 md:gap-8 md:mx-16 flex flex-col justify-center items-center text-uhubblue">
      <div className="flex flex-col items-center justify-center gap-4 md:gap-8 md:w-2/3 mx-auto">
       <div className="flex flex-col items-center justify-center gap-4 md:gap-8 mx-auto">
        
        <div className="font-general-sans-semibold text-base uppercase">
          Franchise
        </div>

        <div className="flex flex-col md:gap-2 text-center font-neue-bold text-2xl md:text-5xl">
        <span>Apply for a Franchise</span>
        <span>in your Area</span>
        </div>
        </div>

        <div className="font-general-sans-regular leading-normal text-center text-md md:text-xl">
        Looking to expand your business horizons? Join our premier logistics franchise, offering top-notch transportation and supply chain solutions. Leverage our industry expertise and established network to ensure swift, reliable deliveries in your area. Become a part of a trusted brand driving growth and efficiency in logistics. </div>
       
      </div>

            <button className="w-36 h-36 flex justify-center items-center text-center rounded-full bg-blue-500 text-white font-general-sans-semibold text-2xl py-2 px-2">
                <a href="https://docs.google.com/forms/d/11osocXiuv9Eb0I9v9qdiq1T2ECmyuEnsFNSHSG49KFg/viewform?edit_requested=true" target="_blank" rel="noreferrer">
                Apply Now
                </a>
            </button>

    </section>
    </div>
    </div>
  );
};

export default Franchise;
