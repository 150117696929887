import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoWhite from "../Assets/Others/Logo White.svg";
import { Menu2 } from "tabler-icons-react";
import SmoothScroll from 'smooth-scroll';

function MobileNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize smooth scrolling for mobile navbar
    new SmoothScroll('a[href*="#"]', {
      speed: 800,
      speedAsDuration: true,
      offset: 64, // Adjust this value to match your header height
    });
  }, []);

  useEffect(() => {
    // Scroll to the element if there's a hash in the URL
    const scrollToHash = () => {
      if (location.hash) {
        const element = document.querySelector(location.hash);
        if (element) {
          window.scrollTo({
            top: element.offsetTop - 64, // Adjust based on your header height
            behavior: 'smooth'
          });
        }
      }
    };

    scrollToHash();
  }, [location]);

  useEffect(() => {
    // Update selected link based on the current path
    if (location.pathname === "/branches") {
      setSelectedLink("branches");
    } else {
      const hash = location.hash.replace("#", "");
      setSelectedLink(hash);
    }
  }, [location]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = (link, path) => {
    setSelectedLink(link);
    setIsOpen(false);
    if (path === "/branches") {
      // Special handling for navigating to branches page
      navigate(path);
    } else {
      // Handle internal page scrolling
      if (location.pathname === "/branches") {
        navigate("/", { replace: true });
      }
      window.location.hash = `#${link}`;
    }
  };

  return (
    <div className="relative z-50">
      <button
        onClick={toggleMenu}
        className="fixed md:hidden mt-[2px] top-3.5 right-5"
      >
        {/* Icon for menu button */}
        <Menu2 className="w-8 h-8 text-uhubblue" />
      </button>

      {isOpen && (
        <div className="fixed inset-0 overflow-hidden z-50">
          <div className="flex items-center justify-between h-full">
            <div
              className="fixed inset-0 bg-uhubblue bg-opacity-75 transition-opacity"
              aria-hidden="true"
              onClick={toggleMenu}
            ></div>

            <div className="relative flex flex-col w-full h-full bg-uhubblue">
              <div className="fixed top-0 right-0 pt-6 pr-5">
                <button
                  onClick={toggleMenu}
                  className="text-white focus:outline-none focus:text-white"
                  aria-label="Close menu"
                >
                  {/* Icon for close button */}
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.7074 18.2926C19.8004 18.3855 19.8741 18.4958 19.9243 18.6172C19.9746 18.7386 20.0005 18.8687 20.0005 19.0001C20.0005 19.1315 19.9746 19.2616 19.9243 19.383C19.8741 19.5044 19.8004 19.6147 19.7074 19.7076C19.6145 19.8005 19.5042 19.8742 19.3828 19.9245C19.2614 19.9747 19.1313 20.0006 18.9999 20.0006C18.8686 20.0006 18.7384 19.9747 18.6171 19.9245C18.4957 19.8742 18.3854 19.8005 18.2924 19.7076L9.99995 11.4138L1.70745 19.7076C1.5198 19.8952 1.26531 20.0006 0.999946 20.0006C0.734582 20.0006 0.480086 19.8952 0.292446 19.7076C0.104805 19.5199 -0.000610346 19.2654 -0.000610352 19.0001C-0.000610357 18.7347 0.104805 18.4802 0.292446 18.2926L8.5862 10.0001L0.292446 1.70757C0.104805 1.51993 -0.000610352 1.26543 -0.000610352 1.00007C-0.000610352 0.734704 0.104805 0.480208 0.292446 0.292568C0.480086 0.104927 0.734582 -0.000488281 0.999946 -0.000488281C1.26531 -0.000488281 1.5198 0.104927 1.70745 0.292568L9.99995 8.58632L18.2924 0.292568C18.4801 0.104927 18.7346 -0.000488286 18.9999 -0.000488281C19.2653 -0.000488276 19.5198 0.104927 19.7074 0.292568C19.8951 0.480208 20.0005 0.734704 20.0005 1.00007C20.0005 1.26543 19.8951 1.51993 19.7074 1.70757L11.4137 10.0001L19.7074 18.2926Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>

              <div className="flex-1 mx-5 h-0 pt-5 pb-5">
                <div className="pl-4 md:hidden w-48 py-1.5 flex justify-start">
                  <img src={logoWhite} alt="Logo" />
                </div>
                {/* Navigation links */}
                <nav className="pl-4 justify-start text-4xl my-12 font-neue-semibold bg-uhubblue text-white">
                  <div className="flex flex-col gap-8">
                    <a
                      href="#aboutus"
                      className={selectedLink === "aboutus" ? "font-neue-bold text-white" : "opacity-50"}
                      onClick={() => handleLinkClick("aboutus")}
                    >
                      About us
                    </a>
                    <a
                      href="#services"
                      className={selectedLink === "services" ? "font-neue-bold text-white" : "opacity-50"}
                      onClick={() => handleLinkClick("services")}
                    >
                      Services
                    </a>
                    <Link
                      to="/branches"
                      className={selectedLink === "branches" ? "font-neue-bold text-white" : "opacity-50"}
                      onClick={() => handleLinkClick("branches", "/branches")}
                    >
                      Branches
                    </Link>
                    <a
                      href="#franchise"
                      className={selectedLink === "franchise" ? "font-neue-bold text-white" : "opacity-50"}
                      onClick={() => handleLinkClick("franchise")}
                    >
                      Franchise
                    </a>
                    <a
                      href="#careers"
                      className={selectedLink === "careers" ? "font-neue-bold text-white" : "opacity-50"}
                      onClick={() => handleLinkClick("careers")}
                    >
                      Careers
                    </a>
                    <a
                      href="#contact"
                      className={selectedLink === "contact" ? "font-neue-bold text-white" : "opacity-50"}
                      onClick={() => handleLinkClick("contact")}
                    >
                      Contact us
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MobileNavbar;
