import React from "react";
import team from "../Assets/Images/team.png";

const Careers = () => {
  // Replace with your actual Google Form URL
  const googleFormUrl = "https://docs.google.com/forms/d/11osocXiuv9Eb0I9v9qdiq1T2ECmyuEnsFNSHSG49KFg/viewform?edit_requested=true";

  return (
    <div id="careers" className="bg-lightorange py-16">
      <div className="pt-4 md:pt-8">
        <section className="mx-5 md:mx-16 flex flex-col justify-center gap-8 md:gap-16 items-center text-uhubblue">
          
          <div className="flex flex-col text-center gap-4 md:gap-6 items-center justify-center md:w-2/3 mx-auto">
            
            <div className="font-general-sans-semibold text-base uppercase">
              Careers
            </div>

            <div className="flex flex-col text-center items-center md:gap-2 justify-center mx-auto">
              <div className="font-neue-bold text-2xl md:text-5xl">
                Insights, Innovation and 
              </div>

              <div className="flex flex-row gap-4 font-neue-bold text-2xl md:text-5xl">
                <span>Inspiration</span>
              </div>
            </div>

            <div className="font-general-sans-regular text-center text-sm">
              At U HUB Logistics, we value insights, foster innovation, and inspire growth. Be part of a dynamic team shaping the future of logistics. Explore career opportunities with us today!
            </div>
          </div>
          
          <div className="mx-auto flex justify-center items-center">
            <img className="w-1/2 md:w-1/4" src={team} alt="team" />
          </div>

          <a href={googleFormUrl} target="_blank" rel="noopener noreferrer">
            <button className="bg-blue-500 font-general-sans-semibold text-lg text-white rounded-full px-6 py-3">
              Apply now
            </button>
          </a>

        </section>
      </div>
    </div>
  );
};

export default Careers;
