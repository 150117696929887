import React from "react";
import Trust from "../Assets/Icons/Trust.png";
import Tech from "../Assets/Icons/Tech.png";
import Pricing from "../Assets/Icons/Pricing.png";
import Competition from "../Assets/Icons/Competition.png";
import Delivan from "../Assets/Icons/Delivan.png";

const AboutUs = () => {
  return (
    <div id="aboutus" className="pt-16 md:pt-24" >
    <section className="mx-5 mb-8 md:mb-16  gap-16 md:gap-32 md:mx-16">
      <div className="flex flex-col md:w-max-full md:flex md:flex-row md:items-center gap-10 md:gap-0">
        
        <div className="md:w-1/2 flex flex-col gap-8 justify-center items-center md:items-start md:justify-start md:pr-4">
        <div className="font-general-sans-semibold text-uhubblue text-base uppercase">
          About Us
        </div>
          <div className="font-neue-bold text-uhubblue text-wrap text-center md:text-start text-3xl md:text-7xl md:pr-20">
            <span>Elevate Your Business <br className="md:hidden"/> with U Hub</span>
          </div>
          <div className="font-general-sans-regular text-sm text-center md:text-justify md:text-base md:pr-40">
          U HUB commenced its operations in the year 2023 with a vision to effectively cater to the needs of the logistics industry by offering Courier, Cargo and Parcel service at affordable rates. Managed by a team of experts with 35 years of experience, we constantly strive and refine our operations to offer offer the above services at atomic precision.
            </div>
          <div className="flex justify-center md:justify-start">
           
          </div>
        </div>
        <div className="hidden md:flex md:w-1/2 justify-center md:justify-end">
          <img
            className="rounded-2xl"
            src="https://images.unsplash.com/photo-1449586919022-f3dfddc48a71?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="About Us"
          />
        </div>
      </div>
      <div>
      <div className="flex flex-col items-center pt-16 justify-center gap-4 md:gap-8 mx-auto">

        <div className="text-center text-uhubblue font-neue-bold text-2xl md:text-5xl mb-8">
        Why Choose us?
        </div>
        <div className="flex flex-row gap-16 md:gap-64">
          <div className="flex flex-col w-16 md:w-36 text-center gap-2 items-center">
            <img className="bg-lightorange rounded-full p-2 md:p-4" src={Trust} alt="Trust"></img>
            <span className="font-neue-bold text-lg md:text-3xl text-center text-uhubblue">Trust & Safety</span>

          </div>
          <div className="flex flex-col w-16 md:w-36 text-center gap-2 items-center">
            <img className="bg-lightorange rounded-full p-2 md:p-4" src={Pricing} alt="Pricing"></img>
            <span className="font-neue-bold text-lg md:text-3xl text-center text-uhubblue">Regular Update</span>
          </div>
          <div className="flex flex-col w-16 md:w-36 text-center gap-2 items-center">
            <img className="bg-lightorange rounded-full p-2 md:p-4" src={Tech} alt="Tech"></img>
            <span className="font-neue-bold text-lg md:text-3xl text-center text-uhubblue">Technology Driven</span>
          </div>
        </div>
        
        <div className="flex flex-row gap-16 md:gap-72">
          <div className="flex flex-col w-16 md:w-36 text-center gap-2 items-center">
            <img className="bg-lightorange rounded-full p-2 md:p-4" src={Competition} alt="Competition"></img>
            <span className="font-neue-bold text-lg md:text-3xl text-center text-uhubblue">Competitve Pricing</span>
          </div>
          <div className="flex flex-col w-16 md:w-36 text-center gap-2 items-center">
            <img className="bg-lightorange rounded-full p-2 md:p-4" src={Delivan} alt="Tech"></img>
            <span className="font-neue-bold text-lg md:text-3xl text-center text-uhubblue">Speedy Delivery</span>
          </div>
        </div>

        </div>
      </div>
    </section>
    </div>
  );
};

export default AboutUs;
