import React from 'react';
import Whatsapp from '../Assets/Others/WhatsApp-icon.svg';

const WhatsappButton = () => {
  return (
    <div className="fixed bottom-4 right-4 md:bottom-8 md:right-8 z-50 ">
      <a href="https://wa.me/+918925909076" target="_blank" rel="noreferrer">
        <img src={Whatsapp} alt="Whatsapp" className="w-12 h-12 md:w-20 md:h-20 shadow-lg hover:shadow-2xl bg-green-500 rounded-full object-cover"/>
      </a>
    </div>
  );
};

export default WhatsappButton;
